import React, { Fragment, useState, useEffect } from 'react'
import Layout from '../components/layout';
import Tabs from '../components/tabs/tabs.component';
import Accordion from '../components/accordion/accordion.component';
import {
  FAQ_TOP_THREE_DATA,
  FAQ_APPLICANT_DATA,
  FAQ_INCOME_DATA,
  FAQ_BORROWING_DATA,
  FAQ_ADDITIONAL_BORROWING_DATA,
  FAQ_PORTING_DATA,
  FAQ_PROPERTY_DATA,
  FAQ_PRODUCT_DATA,
  FAQ_EXISTING_BORROWERS_DATA,
  FAQ_FEES_DATA
} from '../data/criteria.data';

import '../styles/app.scss';

export default function FaqPage() {

  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "frequently asked questions"})
    }
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "frequently asked questions"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);


  return (
    <Layout
      title='Frequently asked questions'
    >
      <div className="page-section row">
        <div className='col-xs-12 col-md-12'>
          <h1 className='page-title'>Frequently asked questions</h1>
          <h2 className='subtitle'>Select FAQs category:</h2>
          <Tabs
            tabs={['Top 3 FAQs', 'Applicant', 'Income', 'Borrowing', 'Additional Borrowing', 'Porting', 'Property', 'Product', 'Existing borrowers', 'Fees']}
            activeTabIndex={selectedTabIndex}
            setActiveTab={setSelectedTabIndex}
            additionalClassName='tabs--horizontal'
            tabContent={[

              [
                <Fragment key={1}>
                  <h2 className='strong'>Top 3 FAQs</h2>
                  {
                    FAQ_TOP_THREE_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} id={d.id} isTealiumLink={true} >
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </Fragment>
              ],
              [
                <React.Fragment key={2}>
                  <h2 className='strong'>Applicant</h2>
                  {
                    FAQ_APPLICANT_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={3}>
                  <h2 className='strong'>Income</h2>
                  {
                    FAQ_INCOME_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={4}>
                  <h2 className='strong'>Borrowing</h2>
                  {
                    FAQ_BORROWING_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={5}>
                  <h2 className='strong'>Additional Borrowing</h2>
                  {
                    FAQ_ADDITIONAL_BORROWING_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={6}>
                  <h2 className='strong'>Porting</h2>
                  {
                    FAQ_PORTING_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={7}>
                  <h2 className='strong'>Property</h2>
                  {
                    FAQ_PROPERTY_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={8}>
                  <h2 className='strong'>Product</h2>
                  {
                    FAQ_PRODUCT_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={9}>
                  <h2 className='strong'>Existing borrowers</h2>
                  {
                    FAQ_EXISTING_BORROWERS_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ],
              [
                <React.Fragment key={10}>
                  <h2 className='strong'>Fees</h2>
                  {
                    FAQ_FEES_DATA['accordionList'].map((d, index) => (
                      <Accordion key={index} title={d.title} isTealiumLink={true}>
                        {d.children.map((item, ix) => (
                          <React.Fragment key={ix}>
                            {item}
                          </React.Fragment>
                        ))}
                      </Accordion>
                    ))
                  }
                </React.Fragment>
              ]
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}